/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Rady a tipy"} description={"Rady a tipy"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"b715elcntxh"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Rádi Vám poradíme"}>
              </Title>

              <Text className="text-box" content={"Pokud si nevíte rady s tím jak vybrat cylindrickou vložku, změřit zadlabací zámek, vybrat kamerový systém pro vaše účely, nebo se neorientujete v bezpečnostních prvcích zde Vám rádi poradíme.&nbsp;&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"ie56tamked7"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Cylindrická vložka"}>
              </Title>

              <Text className="text-box" content={"Při výběru cylindrické vložky je nejdůležitějším parametrem její rozměr. Mezi další parametry patří třída bezpečnosti,&nbsp; zdali chcete knoflík z vnitřní strany, nebo možnost strčit klíče z obou stran."}>
              </Text>

              <Button className="btn-box" style={{"maxWidth":""}} url={"https://klicezamkymolek.cz/vlozka"} use={"url"} href={"https://klicezamkymolek.cz/vlozka"} target={null} content={"Vybíráme vložku"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Zadlabací zámek"}>
              </Title>

              <Text className="text-box" content={"Zadlabací zámky mají parametry na které je potřeba při jejich výběru dbát. Především se jedná o rozměry a v některých případech i jestli jsou dveře pravé nebo levé.<br><br>"}>
              </Text>

              <Button className="btn-box" innerClassName="" style={{"maxWidth":""}} url={"https://klicezamkymolek.cz/zamek"} use={"url"} href={"https://klicezamkymolek.cz/zamek"} target={null} content={"Měříme zámek"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Dveřní kování"}>
              </Title>

              <Text className="text-box" content={"Dveřních kování je celá řada, ale v podstatě se dělí na stavební, interiérové a bezpečnostní.<br><br><br>"}>
              </Text>

              <Button className="btn-box" url={"https://klicezamkymolek.cz/kovani"} use={"url"} href={"https://klicezamkymolek.cz/kovani"} target={null} content={"Vybíráme kování"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}